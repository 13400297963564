function getDurations() {
    var duration = [
        { label: '5min', value: 5},
        { label: '10min', value: 10},
        { label: '15min', value: 15},
        { label: '20min', value: 20},
        { label: '25min', value: 25},
        { label: '30min', value: 30},
        { label: '35min', value: 35},
        { label: '40min', value: 40},
        { label: '45min', value: 45},
        { label: '50min', value: 50},
        { label: '55min', value: 55},
        { label: '1h', value: 60},
        { label: '1h 5min', value: 65},
        { label: '1h 10min', value: 70},
        { label: '1h 15min', value: 75},
        { label: '1h 20min', value: 80},
        { label: '1h 25min', value: 85},
        { label: '1h 30min', value: 90},
        { label: '1h 35min', value: 95},
        { label: '1h 40min', value: 100},
        { label: '1h 45min', value: 105},
        { label: '1h 50min', value: 110},
        { label: '1h 55min', value: 115},
        { label: '2h', value: 120},
        { label: '2h 15min', value: 135},
        { label: '2h 30min', value: 150},
        { label: '2h 45min', value: 165},
        { label: '3h', value: 180},
        { label: '3h 15min', value: 195},
        { label: '3h 30min', value: 210},
        { label: '3h 45min', value: 225},
        { label: '4h', value: 240},
        { label: '4h 30min', value: 270},
        { label: '5h', value: 300},
        { label: '5h 30min', value: 330},
        { label: '6h', value: 360},
        { label: '6h 30min', value: 420},
        { label: '7h', value: 450},
        { label: '7h 30min', value: 480},
        { label: '8h', value: 510},
        { label: '9h', value: 570},
        { label: '10h', value: 630},
        { label: '11h', value: 690},
        { label: '12h', value: 750}
    ];
    return duration;
}

export default getDurations;