<template>
    <v-app id="appointment">

        <v-snackbar
            v-model="show_snackbar"
            :timeout="2000"
            :color="snackbar_color"
            top
            centered
            transition="slide-y-transition"
        >
            <v-icon class="mr-1">mdi-check-circle-outline</v-icon><span class="white--text">{{ snackbar_text }}</span>
        </v-snackbar>

        <!-- Appointment header -->
        <v-card flat>
            <v-card-title>
                <v-flex text-xs-center>
                    <h3 class="text-center font-weight-bold header-space">
                        {{ page_name }}

                        <v-btn
                            class="page_close_btn"
                            icon
                            to="/calendar"
                        >
                            <v-icon color="black" large>mdi-close</v-icon>
                        </v-btn>

                    </h3>
                </v-flex>
            </v-card-title>
        </v-card>
        <!-- End header -->

        <v-divider></v-divider>

        <div class="close_drawer text-center" v-if="drawer">
            <v-btn
                class="mx-2"           
                fab
                color="grey lighten-2"
                @click.stop="drawer = !drawer"
            >
            <v-icon>mdi-close</v-icon>
            </v-btn>
            <p class="mt-3">CLICK TO CLOSE</p>
        </div>

        <!-- Added position:relative; for navigation drawer -->
        <v-row no-gutters style="position:relative;">
            <v-col cols="12" sm="6" md="8" style="height: 690px;overflow-y: scroll;">
                <v-card
                    class="mx-auto stepper-width"
                    flat
                >
                    <!-- Date picker -->
                    <v-col
                        cols="12"
                        sm="8"
                        md="6"
                        class="p-0"
                    >
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="mt-5 pl-6">
                                    <label class="date-picker-text"> {{formatDate}} </label>
                                    <v-icon color="#3c3c3c">mdi-chevron-down</v-icon>
                                </div>
                            </template>
                            <v-date-picker
                                v-model="date"
                                no-title
                                scrollable   
                                @change="save"                  
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <!-- End date picker -->

                    <!-- Stepper -->
                    <v-stepper
                        class="selection_stepper"
                        v-model="step_app"
                        vertical
                        flat
                    >
                        <template v-for="(step, i) in steps_values">
                            <!-- :complete="step_app > n" -->
                            <v-stepper-step
                                :step="i+1"
                                :key="`${step.count}-step`"
                            >
                            </v-stepper-step>

                            <v-stepper-content  :key="`${step.count}-content`" :step="step.count">
                                <!-- height="267px"
                                width="788px" -->
                                <v-card
                                    class="test"
                                    outlined
                                    elevation="1"
                                >
                                    <v-container fluid :class="steps_values.length == 1 ? '' : 'pt-3'">
                                        <!-- Delete service button -->
                                        <div class="d-flex justify-end" v-if="steps_values.length > 1">
                                            <!-- <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }"> -->
                                            <v-btn
                                                x-small
                                                icon
                                                @click="delete_service(i)"
                                                
                                            >
                                                <svg viewBox="0 0 18 18" width="13px" height="13px" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 1.914L16.086 1 9 8.086 1.914 1 1 1.914 8.086 9 1 16.086l.914.914L9 9.914 16.086 17l.914-.914L9.914 9z" fill="#e45a74"></path>
                                                </svg>
                                            </v-btn>
                                                <!-- </template> -->
                                                <!-- <span>Delete service</span> -->
                                            <!-- </v-tooltip> -->
                                        </div>
                                        <!-- End -->

                                        <v-row align="center">
                                            <!-- Start Time -->
                                            <v-col
                                                cols="12"
                                                sm="4"
                                            >
                                                <label class="dialog_label">Start time</label>
                                                <v-select     
                                                    v-model="step.start_time"                                       
                                                    :items="allTimes"
                                                    item-text="label" 
                                                    item-value="value"
                                                    solo
                                                    @change="goToNextStep(step.count, 0)"
                                                    :error="error"
                                                    :rules="[rules.required]"
                                                >
                                                </v-select>
                                            </v-col>
                                            <!-- End start time -->
                                            
                                            <!-- Select Service -->
                                            <v-col
                                                cols="12"
                                                sm="8"
                                            >
                                                <label class="dialog_label">Service</label>
                                                <v-select :items="services_list" item-text="label" item-value="value" placeholder="Choose a service" class="service_select" solo @change="goToNextStep(step.count, 1)"
                                                v-model="step.service" :error="error" :rules="[rules.required]">
                                                    <template #item="{item}" class="service_select">
                                                        <v-alert
                                                            color="#a5dff8"
                                                            border="left"
                                                            elevation="2"
                                                            colored-border
                                                            class="service_dd"
                                                        >
                                                            <div class="service_block">
                                                                <div class="service_block_1">
                                                                    <span class="font-weight-bold">{{ item.name }}</span>
                                                                    <span class="text--disabled">{{ item.time+'min' }}</span>
                                                                </div>
                                                                <div class="service_block_2">{{ $currency }}{{ item.price }}</div>
                                                            </div>
                                                        </v-alert>
                                                    </template>

                                                </v-select>
                                            </v-col>
                                            <!-- End select service -->

                                            <!-- Duration -->
                                            <v-col
                                                class="pt-0"
                                                cols="12"
                                                sm="4"
                                            >
                                                <label class="dialog_label">Duration</label>
                                                <v-select
                                                    :disabled="step.is_duration_disabled"
                                                    v-model="step.duration"
                                                    :items="durations"
                                                    item-text="label" 
                                                    item-value="value"
                                                    solo
                                                    @change="goToNextStep(step.count, 0)"
                                                    :error="error"
                                                    :rules="[rules.required]"
                                                ></v-select>
                                            </v-col>
                                            <!-- End duration -->

                                            <!-- Staff selection -->
                                            <v-col
                                                class="pt-0"
                                                cols="12"
                                                sm="8"
                                            >   
                                                <label class="dialog_label">Staff</label>
                                                <v-select
                                                    v-model="step.staff"
                                                    :items="staffs"
                                                    item-text="employee_name"
                                                    item-value="id"
                                                    placeholder="Select staff"
                                                    @change="goToNextStep(step.count, 0)"
                                                    solo
                                                    :error="error"
                                                    :rules="[rules.required]"
                                                ></v-select>
                                            </v-col>
                                            <!-- End staff selection -->

                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-stepper-content>
                        </template>

                    </v-stepper>
                    <!-- End stepper -->

                    <!-- Appointment notes -->
                    <v-col
                        cols="12"
                        md="12"
                        class="pl-16 pr-9"
                    >
                        <div class="app_notes">Appointment notes</div>
                        <v-textarea
                            v-model="app_notes"
                            solo
                            name="notes"
                            placeholder="Add an appointment note (visible to staff only)"
                        ></v-textarea>
                    </v-col>
                    <!-- End appointment notes -->
                </v-card>    
            </v-col>

            <v-divider vertical class="vertical-divider"></v-divider>

            <v-col cols="6" md="4" >
                <v-card flat>
                    <!-- Search box -->
                    <div class="pa-6 pb-0" v-if="selected_client.id == ''">
                        <v-text-field
                            solo
                            placeholder="Search Client"
                            prepend-inner-icon="mdi-magnify"
                            @click.stop="drawer = !drawer; client_search = ''"
                        ></v-text-field>
                    </div> 

                    <v-card v-if="selected_client.id != ''" link flat class="pa-6 pb-5" @click="drawer = true">
                        <div class="d-flex align-center">
                            <v-avatar
                                color="grey lighten-2"
                                size="60"
                                class="mr-3"
                                v-if="selected_client.customer_name == 'Default Customer'"
                            >
                                <span style="width: 32px;height: 32px;">
                                    <svg viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" stroke="#101928"><path d="M.5 21c0-5.5313 4.1043-9.5 9.5-9.5s9.5 3.9687 9.5 9.5v.5H.5V21z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.5 6c0-3.0374-2.4626-5.5-5.5-5.5S4.5 2.9626 4.5 6s2.4626 5.5 5.5 5.5 5.5-2.4626 5.5-5.5z"></path></g></svg>
                                </span>
                            </v-avatar>

                            <v-avatar class="mr-3" size="60" v-if="selected_client.customer_name != 'Default Customer'">
                                <v-icon color="#000" class="grey lighten-4" >
                                    {{ selected_client.customer_name.charAt(0) }}
                                </v-icon>
                            </v-avatar>

                            <div class="d-flex flex-column">
                                <div class="font-weight-medium fs-1_1">
                                    {{ selected_client.customer_name }}
                                </div>
                                <div class="text--disabled text-subtitle-1" style="line-height: 17px;">
                                    {{ selected_client.email_id }}
                                </div>
                            </div>
                            

                            <div class="ml-auto">
                                <v-icon class="float-right">mdi-chevron-right</v-icon>
                            </div>
                        </div>
                    </v-card>
                    <!-- End search box -->

                    <v-divider></v-divider>

                    <!-- Search info -->
                    <v-card
                        color="white"
                        flat
                        height="450"
                        tile
                    > 
                        <v-container class="px-15" fluid fill-height>
                            <v-layout align-center justify-center>
                                <div class="text-md-center">
                                    <p class="custom_search_icon">
                                        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#FBD74C" cx="22.5" cy="17.5" r="9.5"></circle><g transform="translate(2 2)" stroke="#101928" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M34.642 34.642L44.5 44.5"></path><circle cx="20.5" cy="20.5" r="20"></circle><path d="M29.5 30.5h-18v-2.242a3.999 3.999 0 012.866-3.838c1.594-.472 3.738-.92 6.134-.92 2.356 0 4.514.456 6.125.932a4.003 4.003 0 012.875 3.841V30.5z"></path><circle cx="20.5" cy="15.5" r="4.5"></circle></g></g></svg>
                                    </p>
                                    <p>Use the search to add a client, or keep empty to save as walk-in.</p>
                                </div>
                            </v-layout>
                        </v-container>
                    </v-card>
                    <!-- End search info -->

                    <v-divider></v-divider>

                    <!-- Checkout btn -->
                    <v-card flat>
                        <div class="pa-5">
                            <p class="font-weight-medium text-center total_text">Total: {{ total_text }}</p>
                            <v-row align="center">
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <div class="my-2 text-center">
                                        <v-btn
                                            class="express_checkout custom-transform-class font-weight-medium"
                                            large
                                            :disabled="disable_express_checkout"
                                            color="white"
                                            @click="express_checkout()"
                                        >
                                            Express checkout
                                        </v-btn>
                                    </div>
                                </v-col>
                                 <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <div class="my-2 text-center">
                                        <v-btn
                                            class="save_app font-weight-medium"
                                            large
                                            dark
                                            color="#101928"
                                            depressed
                                            @click="save_appointment()"
                                        >
                                            Save appointment
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                    <!-- End Checkout -->

                </v-card>
            </v-col>

            <!-- Search client popup -->
            <v-navigation-drawer
                v-model="drawer"
                absolute
                temporary
                right
                width="490px"
            >
                <!-- Search box -->
                <v-col class="pb-0" cols="12" md="12" >
                    <v-card flat>
                        <div class="pa-3 pb-0">
                            <v-text-field
                                solo
                                placeholder="Search Client"
                                prepend-inner-icon="mdi-magnify"
                                v-model="client_search"
                                :append-icon="client_search != '' ? 'mdi-close-circle' : ''"
                                @click:append="client_search = ''"
                                class="custom_append_icon"
                            ></v-text-field>
                        </div> 
                    </v-card>
                </v-col>     
                <!-- End search box -->     

                <v-divider></v-divider>

                <div class="text-center pa-3">
                    <div @click="create_client_page()" class="create_cli_btn">
                        <v-icon class="mr-1" color="#037aff">mdi-plus</v-icon>
                        <span class="create_cli_text">Create new client</span>
                    </div>
                </div>

                <v-divider></v-divider>
                <v-list v-if="Object.keys(search_client_result).length != 0" two-line class="pt-0">
                    <template 
                        v-for="(item, i) in search_client_result"
                    >
                        <v-list-item :key="item.id" link @click="clientClick(item)">
                            <v-list-item-avatar size="60">
                                <v-icon color="#000" class="grey lighten-4" >
                                    {{ item.customer_name.charAt(0) }}

                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ item.customer_name }}</v-list-item-title>

                                <v-list-item-subtitle>{{ item.email_id }}</v-list-item-subtitle>

                                <v-list-item-subtitle>{{ item.phone_number }}</v-list-item-subtitle>

                            </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="i"></v-divider>
                    </template>
                </v-list>

                <v-container v-if="Object.keys(search_client_result).length == 0" class="px-15 py-14" fluid>
                    <v-layout align-center justify-center>
                        <div class="text-md-center">
                            <p class="custom_search_icon">
                                <v-icon size="48px">$vuetify.icons.SearchSvg</v-icon>
                            </p>
                            <p>No clients found</p>
                        </div>
                    </v-layout>
                </v-container>

            </v-navigation-drawer>
            <!-- End Search client popup -->

        </v-row>
    </v-app>
</template>

<script>
import moment from 'moment'
import getTimes from '../helpers/get_times.js';
import getDurations from '../helpers/get_durations.js';
import CommonApi from '@/services/CommonApi'

export default {
    name: "Appointment",

    mounted() {
        this.getDropdownsList();
        this.appointment_id = this.$route.params.appointment_id;
        this.page_name = this.$route.name;
    },
    data: () => ({
        drawer: null,
        step_app: 1,
        steps_values: [
            { count: 1, start_time: '0:00', service: null, duration: null, staff: null, is_duration_disabled: true }
        ],
        date: '',
        menu: false,
        allTimes: getTimes(),
        services_list: [],
        durations: getDurations(),
        staffs: [],
        clients: [],
        selected_client : { id : '', customer_name : '', email_id : '' },
        app_notes: '',
        disable_express_checkout: true,
        show_snackbar: false,
        snackbar_text: '',
        snackbar_color: '',
        total_text: 'Free (0min)',
        appointment_id: null,
        page_name: '',
        error: false,
        rules: {
            required: value => !!value || 'This field is required.'
        },
        client_search : ''
    }),
    methods: {
        save_appointment() {
            let service_data = [];
            this.steps_values.forEach(ele => {
                if(ele.start_time != null && ele.service != null && ele.duration != null && ele.staff != null) {

                    let service_det = this.services_list.find(o => o.value === ele.service);
                    let end_time = moment(ele.start_time, 'HH:mm')
                    .add(service_det.time, 'minutes')
                    .format('HH:mm')

                    let service = { 
                        service_name : ele.service, 
                        rate : service_det.price, 
                        duration : service_det.time, 
                        from_time : moment(ele.start_time,'HHmmss').format("HH:mm:ss"), 
                        to_time : moment(end_time,'HHmmss').format("HH:mm:ss"),
                        staff : ele.staff
                    }
                    service_data.push(service);
                }
            })
            if(service_data.length > 0) {
                let data = { 
                    name: this.appointment_id,
                    client: (this.selected_client.id ? this.selected_client.id : 'Default Customer'),
                    date: this.date,
                    services: service_data,
                    notes: this.app_notes
                }
                const post_data = new FormData();
                post_data.append("appointment_data", JSON.stringify(data))
                CommonApi.post_data('innosoft_salon.api.book_appointment', post_data)
                .then(res => {
                    if(res) {
                        this.show_snackbar = true;
                        this.snackbar_text = 'Appointment booked successfully...';
                        if(this.appointment_id) {
                            this.snackbar_text = 'Appointment updated successfully...';
                        }
                        this.snackbar_color = 'success';

                        setTimeout(function () {
                            this.$router.push('/calendar');
                        }.bind(this), 3000)

                    }
                });
            } 
        },
        express_checkout() {
            let service_data = [];
            this.steps_values.forEach(ele => {
                if(ele.start_time != null && ele.service != null && ele.duration != null && ele.staff != null) {

                    let service_det = this.services_list.find(o => o.value === ele.service);
                    let end_time = moment(ele.start_time, 'HH:mm')
                    .add(service_det.time, 'minutes')
                    .format('HH:mm')

                    let service = { 
                        service_name : ele.service, 
                        rate : service_det.price, 
                        duration : service_det.time, 
                        from_time : moment(ele.start_time,'HHmmss').format("HH:mm:ss"), 
                        to_time : moment(end_time,'HHmmss').format("HH:mm:ss"),
                        staff : ele.staff
                    }
                    service_data.push(service);
                }
            })
            if(service_data.length > 0) {
                let data = { 
                    name: this.appointment_id,
                    client: (this.selected_client.id ? this.selected_client.id : 'Default Customer'),
                    date: this.date,
                    services: service_data,
                    notes: this.app_notes
                }
                const post_data = new FormData();
                post_data.append("appointment_data", JSON.stringify(data))
                CommonApi.post_data('innosoft_salon.api.book_appointment', post_data)
                .then(res => {
                    if(res) {
                        this.$router.push('/checkout/'+res.data.name+'/'+this.$route.query.employeeId);
                    }
                });
            } 
        },
        delete_service(index) {
            this.$swal({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    this.steps_values.splice(index, 1);
                    this.calculate_total_price_time();

                    this.show_snackbar = true;
                    this.snackbar_text = 'Deleted successfully...';
                    this.snackbar_color = 'success';
                }
            });
        },
        goToNextStep(step, is_service) {
            // To change duration drop down based on service selection
            if(is_service == 1) {
                let objIndex = this.steps_values.findIndex((obj => obj.count == step));
            
                let get_service = this.steps_values[objIndex].service;
                let service_duration = this.services_list.find(o => o.value === get_service).time;

                this.steps_values[objIndex].duration = service_duration;
                this.steps_values[objIndex].is_duration_disabled = false;
            } 
            
            /* Check and create next stepper text fields */
            var field_empty = false;
            for(var i in this.steps_values) {
                if(this.steps_values[i].start_time == null || this.steps_values[i].service == null || this.steps_values[i].duration == null || this.steps_values[i].staff == null) field_empty = true;
            }
            if(!field_empty) {
                this.step_app = this.step_app + 1;
                let step_data = { count: this.step_app, start_time: '0:00', service: null, duration: null, staff: null, is_duration_disabled: true }
                this.steps_values.push(step_data);
            }
            /* End */
            
            // Total text above checkout buttons
            this.calculate_total_price_time();
        },
        calculate_total_price_time() {
            var total_price_count = 0;
            var durations_count = 0;
            for(var val in this.steps_values) {
                if(this.steps_values[val].service != null) {
                    let service_price = this.services_list.find(o => o.value === this.steps_values[val].service).price;
                    total_price_count += parseInt(service_price);
                }
                if(this.steps_values[val].duration != null) {
                    let duration = this.steps_values[val].duration;
                    durations_count += parseInt(duration);
                }
            }
            let duration_text = '';
            if(durations_count > 55) {
                var hours = Math.floor(durations_count / 60);          
                var minutes = durations_count % 60;

                duration_text = hours+'h '+minutes+'min';
            } else {
                duration_text = durations_count+'min';
            }
            if(total_price_count == 0) {
                this.total_text = 'Free ('+duration_text+')';
                this.disable_express_checkout = true;
            } else {
                this.total_text = this.$currency+total_price_count+' ('+duration_text+')';
                if(this.steps_values[0].start_time != null && this.steps_values[0].service != null && this.steps_values[0].duration != null && this.steps_values[0].staff != null) {
                    this.disable_express_checkout = false;
                }
            }
        },
        save (date) {
            this.$refs.menu.save(date)
        },
        async getDropdownsList() {
            let branch_param = { branch: localStorage.getItem("branch") };
            branch_param = new URLSearchParams(branch_param);
            await CommonApi.get_data('innosoft_salon.api.get_all_data', branch_param)
            .then(res => {
                if (res.status_code == 200) {
                    this.staffs = res.data.staff_list;
                    let services = res.data.service_list;
                    services.forEach(ele => {
                        let service_sentence = ele.service_name+', ('+ele.duration+'min, '+this.$currency+ele.rate+')';
                        let full_array = {
                            value : ele.id,
                            name : ele.id,
                            time : ele.duration,
                            price : ele.rate,
                            label : service_sentence
                        };
                        this.services_list.push(full_array);
                    });
                    this.clients = res.data.client;

                    if(this.appointment_id) {
                        this.getAppointmentDetails();
                    } else {
                        if(this.$route.query.startDate) {
                            this.date = this.$route.query.startDate;

                            let q_start = parseInt(this.$route.query.startTime);
                            let start_time = moment().startOf('day')
                                .seconds(q_start)
                                .format('H:mm');
                            this.steps_values = [
                                { count: 1, start_time: start_time, service: null, duration: null, staff: this.$route.query.employeeId, is_duration_disabled: true }
                            ];
                        } else {
                            this.date = new Date().toISOString().substr(0, 10);
                        }
                    }
                }
            })
        },
        clientClick(client) {
            this.selected_client = client;
            this.drawer = false;
        },
        create_client_page() {
            this.$router.push('/create_client');
        },
        async getAppointmentDetails() {
            let param = { appointment_id: this.appointment_id };
            const params = new URLSearchParams(param);

            await CommonApi.get_data('innosoft_salon.api.get_appointment_details', params)
            .then(res => {
                if (res.status_code == 200) {
                    this.date = res.data.date

                    let services = res.data.services;

                    this.steps_values = [];

                    services.forEach(ele => {
                        let start_time = moment(ele.from_time, "HH:mm:ss").format('H:mm');
                        let service_data = {
                            count : this.step_app,
                            start_time : start_time,
                            service : ele.service_id,
                            duration : ele.duration,
                            staff : ele.staff,
                            is_duration_disabled : false
                        };
                        
                        this.steps_values.push(service_data);
                        this.step_app++;
                    });

                    this.app_notes = res.data.notes;

                    if(res.data.client) {
                        let client_det = this.clients.find(o => o.id === res.data.client);
                        this.selected_client = client_det;
                    }

                    this.calculate_total_price_time();
                }
            })
        }
    },
    computed: {
        search_client_result() {
			if(this.client_search){
				let newObj = {};
                let search_text = this.client_search.toLowerCase();
				for (let key in this.clients) {
					if (this.clients[key].customer_name.toString().toLowerCase().match(search_text)) {
						newObj[key] = this.clients[key];
					} else if(this.clients[key].email_id != null && this.clients[key].email_id.toString().toLowerCase().match(search_text)) {
                        newObj[key] = this.clients[key];
                    } else if(this.clients[key].phone_number != null && this.clients[key].phone_number.toString().toLowerCase().match(search_text)) {
                        newObj[key] = this.clients[key];
                    }
				}
				return newObj;
			}else{
				return this.clients;
			}
		},
        formatDate() {
            return this.date ? moment(this.date).format('dddd, D MMMM Y') : "";
        }
    }
};

</script>